import React, { Component } from 'react'

//styling
import Layout from '../components/layout'


class ApiPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		return (
			<Layout data={this.props.data.dataJson.api}>
				<div className="docs">
					<div>
						<h1 id="sec_1">API Documentation</h1>
						<div>
							<h2>Introduction</h2>
							<p>All requests require an API key. API keys are passed via the Authorization HTTP header:</p>

							<div>
								<pre><code>
									Authorization: Bearer [**API Key**]
								</code></pre>
							</div>
						</div>

						<h2 id="sec_3">Projects</h2>
						<p>A project represents a campaign in ThankView.</p>

						<h4>List Projects:</h4>
						<span>Get a list of all the projects in your account.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/projects <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<br />

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({
									"success": "true", 
									"data": { 
										"projects": [ {
											"id": 1,
											"name": "Project 1",
											"created_by": "User 1",
										}, {
											"id": 2,
											"name": "Project 2",
											"created_by": "User 2" }
										] } }, null, 2) 
								}
							</code></pre>
						</div>

						<h2 id="sec_4">Recipients:</h2>
						<p>Recipients are the contacts within each project.</p>

						<h4>List Recipients:</h4>
						<span>Get a list of all recipients of a project. Set a limit parameter for the number of results per request (max 100). Set a page parameter to return results after a number of records. Add a search parameter to search by first name, last name, and email.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/recipient?project_id=1&limit=100&page=0&search=first <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({
									"success": "true", 
									"data": { 
										"page": 0,
										"limit": 100,
										"recipients": [ {
											"id": 1,
											"first_name": "First 1",
											"last_name": "Last 1",
											"email": "email1@email.com"
										}, {
											"id": 2,
											"first_name": "First 2",
											"last_name": "Last 2",
											"email": "email2@email.com"}
										] } }, null, 2) 
								}
							</code></pre>
						</div>

						<h4 id="sec_5">Add Recipients:</h4>
						<span>Add a list of recipients to a project. Emails are unique per project. Recipients that have the same email will have their data updated with the latest information.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								POST https://api.thankview.com/api/recipient <br/>
									Content-Type: application/json <br/>
									Authorization: Bearer [**API Key**] <br/>
								{JSON.stringify({
									"project_id": "1", 
									"recipients": [ {
										"title": "Title",
										"name": "First Name 1",
										"last_name": "Last Name 1",
										"preferred_name": "Nickname",
										"email": "email1@email.com",
										"notes": "",
										"donor_id": "1000001",
										"phone": "222-333-4444",
										"company": "ThankView",
										"last_donation": "100.00",
										"designation": "Alumni Fund"
									}, {
										"title": "Title",
										"name": "First Name 2",
										"last_name": "Last Name 2",
										"preferred_name": "Nickname",
										"email": "email2@email.com",
										"notes": "",
										"donor_id": "1000002",
										"phone": "333-444-5555",
										"company": "ThankView",
										"last_donation": "150.00",
										"designation": "Athletics Fund"}
									] }, null, 2) }
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({ 
									"success": "true", 
									"data": { 
										"updated": 1,
										"invalid": 0,
										"new_records": 1,
										"existing_records": 0,
										"ids": [1, 2, 3],
									} }, function(k,v){if(v instanceof Array) return JSON.stringify(v); return v;}, 2).replace(/\"\[/g, '[').replace(/\]\"/g,']') }
							</code></pre>
						</div>

						<h4 id="sec_recipient_del">Delete Recipient:</h4>
						<span>Delete a recipient from a project.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								DELETE https://api.thankview.com/api/recipient/{"{"}id{"}"} <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**] <br/>
								{ JSON.stringify({ "project_id": "1" }, null, 2) }
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({ "success": "true" }, null, 2) }
							</code></pre>
						</div>

						<h2 id="sec_video">Videos:</h2>
						<p>Upload videos to your portal and attach videos to recipients of a campaign.</p>

						<h4>List Videos:</h4>
						<span>Get a list of all videos in your portal. Set a limit parameter for the number of results per request (max 100). Set a page parameter to return results after a number of records. Add a search parameter to search by title, description, and tags.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/video?limit=100&page=0&search=title <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({
									"success": "true", 
									"data": { 
										"page": 0,
										"limit": 100,
										"videos": [ {
											"id": 1,
											"title": "Title 1",
											"video_path": "https://media.thankview.com/video1.mp4",
											"description": "My first video"
										}, {
											"id": 2,
											"title": "Title 2",
											"video_path": "https://media.thankview.com/video2.mp4",
											"description": "My second video"}
										] } }, null, 2) 
								}
							</code></pre>
						</div>

						<h4 id="sec_video_upload">Upload a Video:</h4>
						<span>Upload a video to your portal, mp4 file recommended. This can take some time especially with larger video sizes.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								POST https://api.thankview.com/api/video <br/>
									Content-Type: multipart/form-data; boundary=MultipartBoundry <br/>
									Authorization: Bearer [**API Key**] <br/>
								<br/>
									--MultipartBoundry <br/>
									Content-Disposition: form-data; name="file"; filename="video.mp4" <br/>
									Content-Type: video/mp4 <br/>
								<br/>
									rawvideocontent <br/>
									--MultipartBoundry <br/>
									Content-Disposition: form-data; name="title" <br/>
								<br/>
									A new video <br/>
									--MultipartBoundry <br/>
									Content-Disposition: form-data; name="description" <br/>
								<br/>
									Video description <br/>
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({ 
									"success": "true", 
									"data": { 
										"id": 5,
										"title": "A new video",
										"video_path": "https://media.thankview.com/video5.mp4",
										"description": "Video description",
										"tags": "tag1,tag2",
									} }, null, 2) }
							</code></pre>
						</div>

						<h4 id="sec_video_delete">Delete Video:</h4>
						<span>Delete a video from your portal.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								DELETE https://api.thankview.com/api/video/{"{"}id{"}"} <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**] <br/>
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({ "success": "true" }, null, 2) }
							</code></pre>
						</div>

						<h4 id="sec_video_attach">Attach Video To Recipients:</h4>
						<span>Attach up to 1,000 recipients to a video. Set the video id and ids of the recipients.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								POST https://api.thankview.com/api/video/attach <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**] <br/>
								{ JSON.stringify({
									"project_id": "1", 
									"video_id": "2", 
									"recipient_ids": [1, 2, 4, 5]}, 
									function(k,v){if(v instanceof Array) return JSON.stringify(v); return v;}, 2).replace(/\"\[/g, '[').replace(/\]\"/g,']')
								}
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({ "success": "true" }, null, 2) }
							</code></pre>
						</div>

						<h2 id="sec_send">Sends:</h2>
						<p>Schedule and sends ThankViews within a campaign.</p>

						<h4>List Statuses:</h4>
						<span>List a summary of the statuses of your sends within a campaign.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/send?project_id=1 <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({ 
									"success": "true", 
									"data": { 
										"ready_to_send": 2,
										"scheduled": 1,
										"sending": 0,
										"delivered": 2,
										"bounced": 0,
									} }, null, 2) }
							</code></pre>
						</div>

						<h4 id="sec_send_ready">List Ready To Send:</h4>
						<span>List recipients ready to send within a campaign.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/send/readyToSend?project_id=1&limit=100&page=0 <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({
									"success": "true", 
									"data": { 
										"page": 0,
										"limit": 100,
										"recipients": [ {
											"id": 1,
											"first_name": "First 1",
											"last_name": "Last 1",
											"email": "email1@email.com"
										}, {
											"id": 2,
											"first_name": "First 1",
											"last_name": "Last 1",
											"email": "email1@email.com"}
										] } }, null, 2) 
								}
							</code></pre>
						</div>

						<h4 id="sec_send_scheduled">List Scheduled:</h4>
						<span>List recipients that are scheduled within a campaign.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/send/scheduled?project_id=1&limit=100&page=0 <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({
									"success": "true", 
									"data": { 
										"page": 0,
										"limit": 100,
										"recipients": [ {
											"id": 1,
											"first_name": "First 1",
											"last_name": "Last 1",
											"email": "email1@email.com"
										}, {
											"id": 2,
											"first_name": "First 1",
											"last_name": "Last 1",
											"email": "email1@email.com"}
										] } }, null, 2) 
								}
							</code></pre>
						</div>

						<h4 id="sec_send_sending">List Sending:</h4>
						<span>List recipients that are being sent within a campaign.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/send/sending?project_id=1&limit=100&page=0 <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({
										"success": "true", 
										"data": { 
											"page": 0,
											"limit": 100,
											"recipients": [ {
												"id": 1,
												"first_name": "First 1",
												"last_name": "Last 1",
												"email": "email1@email.com"
											}, {
												"id": 2,
												"first_name": "First 1",
												"last_name": "Last 1",
												"email": "email1@email.com"}
											] } }, null, 2) 
								}
							</code></pre>
						</div>

						<h4 id="sec_send_delivered">List Delivered:</h4>
						<span>List recipients that have been delievered a ThankView within a campaign.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/send/delivered?project_id=1&limit=100&page=0 <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
							{ JSON.stringify({
									"success": "true", 
									"data": { 
										"page": 0,
										"limit": 100,
										"recipients": [ {
											"id": 1,
											"first_name": "First 1",
											"last_name": "Last 1",
											"email": "email1@email.com"
										}, {
											"id": 2,
											"first_name": "First 1",
											"last_name": "Last 1",
											"email": "email1@email.com"}
										] } }, null, 2) 
							}
							</code></pre>
						</div>

						<h4 id="sec_send_bounced">List Bounced:</h4>
						<span>List recipients that have been delievered a ThankView within a campaign.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/send/bounced?project_id=1&limit=100&page=0 <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
							{ JSON.stringify({
									"success": "true", 
									"data": { 
										"page": 0,
										"limit": 100,
										"recipients": [ {
											"id": 1,
											"first_name": "First 1",
											"last_name": "Last 1",
											"email": "email1@email.com"
										}, {
											"id": 2,
											"first_name": "First 1",
											"last_name": "Last 1",
											"email": "email1@email.com"}
										] } }, null, 2) 
							}
							</code></pre>
						</div>

						<h4 id="sec_send_now">Send Now:</h4>
						<span>Send ThankViews to all of your recipients within a campaign immediately. You can not cancel any ThankViews that are sent immediately.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								POST https://api.thankview.com/api/send/now <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**] <br/>
								{ JSON.stringify({
									"project_id": "1", 
									"recipient_ids": [1, 2, 4, 5]}, 
									function(k,v){if(v instanceof Array) return JSON.stringify(v); return v;}, 2).replace(/\"\[/g, '[').replace(/\]\"/g,']')
								}
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({ 
									"success": "true", 
									"data": { 
										"count": 3,
										"scheduled": "2020-04-30 12:00:00",
									} }, null, 2) }
							</code></pre>
						</div>

						<h4 id="sec_send_schedule">Schedule:</h4>
						<span>Schedule ThankViews for all recipients within a campaign to send at a future date. The date must be in UTC timezone and in the format <strong>Y-m-d H:i:s</strong>.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								POST https://api.thankview.com/api/send/schedule <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**] <br/>
								{ JSON.stringify({
									"project_id": "1", 
									"schedule": "2030-01-01 12:00:00", 
									"recipient_ids": [1, 2, 4, 5]}, 
									function(k,v){if(v instanceof Array) return JSON.stringify(v); return v;}, 2).replace(/\"\[/g, '[').replace(/\]\"/g,']')
								}
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({ 
									"success": "true", 
									"data": { 
										"count": 3,
										"scheduled": "2030-01-01 12:00:00",
									} }, null, 2) }
							</code></pre>
						</div>

						<h4 id="sec_send_cancel">Cancel Scheduled:</h4>
						<span>Cancel all scheduled ThankViews in a campaign. ThankViews that are sending can not be canceled.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								POST https://api.thankview.com/api/send/cancelScheduled <br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**] <br/>
								{ JSON.stringify({
									"project_id": "1", 
									"recipient_ids": [1, 2, 4, 5]}, 
									function(k,v){if(v instanceof Array) return JSON.stringify(v); return v;}, 2).replace(/\"\[/g, '[').replace(/\]\"/g,']')
								}
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{ JSON.stringify({ 
									"success": "true", 
									"data": { 
										"count": 3,
										"scheduled": "2030-01-01 12:00:00",
									} }, null, 2) }
							</code></pre>
						</div>

						<h2 id="sec_6">Metrics:</h2>
						<p></p>

						<h4>Metric Details:</h4>
						<span>Get metrics for a single project.</span>

						<h5>Request</h5>
						<div>
							<pre><code>
								GET https://api.thankview.com/api/metrics?project_id=1&sent_start=YYYY-MM-DD&sent_end=YYYY-MM-DD<br/>
								Content-Type: application/json <br/>
								Authorization: Bearer [**API Key**]
							</code></pre>
						</div>

						<h5>Response</h5>
						<div>
							<pre><code>
								{JSON.stringify({
									"success": "true", 
									"data": { 
										"metrics": [ {
											"project_id": 92,
											"project_name": "project 1",
											"project_type": "Thank Yous",
											"recorder_name": "name",
											"recipient_id": 8641,
											"title": "",
											"first_name": "First 1",
											"last_name": "Last 1",
											"sent_to": "email1@email.com",
											"email": "email1@email.com",
											"phone": "+12345678920",
											"stars": 3,
											"donor_id": "123",
											"birthday": "2021-03-16",
											"notes": null,
											"sent_date": "2021-03-12 16:48:08",
											"bounced": 0,
											"bounced_reason": null,
											"unsubscribe": 0,
											"unsubscribe_date": null,
											"reported_spam": null,
											"opened": 0,
											"opened_date": null,
											"clicked": 0,
											"watched_0": 1,
											"watched_25": 1,
											"watched_50": 1,
											"watched_75": 1,
											"watched_100": 0,
											"cta_clicked": 1,
											"views": 1,
											"downloads": 0,
											"shares": 0,
											"reply": null,
											"video_url": "https://dev-thankview.com/video/2601f32c2d4920?source=export",
											"video_download": "https://dev-thankview.com/videos-processed/final-601f32e84c7aa-139.mp4",
											"video_url_id": "2601f32c2d4920",
											"device": null,
											"city": null,
											"state": null,
											"country": null,
											"preferred_name": null,
											"company": null,
											"high_school": null,
											"graduation_year": null,
											"race": null,
											"major": null,
											"department": null,
											"address": null,
											"address2": null,
											"zip_code": null,
											"counselor": null,
											"sport": null,
											"interest": null,
										} ] } }, null, 2) }
							</code></pre>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export const query = graphql`
  query {
      dataJson {
        api {
          name
          contents {
          	id
            data
          }
        }
      }
    }   
  `;

export default ApiPage; 
